import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='YOUR INSURANCE IN COLORADO' activeCard='colorado' image={'/Colorado_BG.jpeg'}>
          <p>
            Interested in insurance in Colorado? Apollo Insurance can provide affordable solutions for our clients in Colorado. As consumers ourselves, we know
            the stress of witnessing rates increase on premiums this year. We listen to your needs and make sure you receive affordable insurance in Colorado.
          </p>
          <p>Contact us today to secure quality and affordable healthcare in the state of Colorado.</p>
        </Content>
      </div>
    </Layout>
  );
};
